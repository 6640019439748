import React from "react";
import axios from 'axios';
import moment from 'moment';
import {API_ROOT} from '../../api-config';

export function getVehicles(response) {
    var fetch_data = true;
    var current_timestamp = Math.round((new Date()).getTime() / 100);
    if (fetch_data) {
        axios.defaults.withCredentials = true;
        axios.get(`${API_ROOT}/vehicles/`, {withCredentials: true})
            .then(function (ajax_response) {
                var vehicles = {};
                vehicles.data = {};
                vehicles.data = ajax_response.data;
                vehicles.date_fetched = current_timestamp;
                if (Object.keys(vehicles.data).length > 0) {
                    localStorage.setItem('vehicles', JSON.stringify(vehicles));
                    response(vehicles.data);
                }
            })
            .catch(function (error) {
                response({});
            });
    }
}


export function addVehiclePhoto(response) {

}

export default getVehicles;
import React, {Component} from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    Input, Row, UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap';
import Map, {
    Marker,
    Popup,
    NavigationControl,
    FullscreenControl,
    ScaleControl,
    Source,
    Layer,
    GeolocateControl,
    MapRef,
} from 'react-map-gl/maplibre';
import 'maplibre-gl/dist/maplibre-gl.css';

import axios from 'axios';
import {API_ROOT} from '../../api-config';
import Swal from 'sweetalert2'
import "react-datepicker/dist/react-datepicker.css";
import circle from '@turf/circle';
import Pin from './pin';

class AddLocation extends React.Component {
    constructor(props) {
        super(props);
        this.updateValue = this.updateValue.bind(this);
        this.onMarkerDragEnd = this.onMarkerDragEnd.bind(this);
        this.state = {
            mapRef: null,
            markerRef: null,
            modal: false,
            responses: {},
            postal: "",
            address: "",
            city: "",
            province: "NB",
            country: "CA",
            name: "",
            processing: false,
            lon: 0,
            lat: 0,
            radius: 150,
            section: 1,
            radius_options: [50, 100, 150, 175, 200, 250, 300, 350, 400, 450, 500, 600, 700, 800, 900, 1000, 1250, 1500],
            circle: {"type": "Feature", "properties": {"foo": "bar"}, "geometry": {"type": "Polygon", "coordinates": []}}
        };
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal
        });
    };

    selectCallNotes = (name, value) => {
        if (typeof value != "string") {
            value = value.target.value;
        }
        this.setState({
            [name]: value
        });
    };

    changeProvince = (province) => {
        this.setState({
            province: province,
        });
    }

    updateValue(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    searchAddress = () => {
        if (this.state.name == "" || this.state.address == "" || this.state.city == "") {
            Swal("Error", "Please enter a name, address, and city", "error");
        } else {
            this.setState({
                section: 2
            });
            var self = this;
            axios.post(API_ROOT + '/geocode', {
                country: this.state.country,
                province: this.state.province,
                postal: this.state.postal,
                city: this.state.city,
                address: this.state.address
            })
                .then(function (response) {
                    self.setState({
                        lon: response.data.lon,
                        lat: response.data.lat,
                        circle: circle([response.data.lon, response.data.lat], self.state.radius, {steps: 50, units: 'meters', properties: {foo: 'bar'}})
                    });
                    self.state.mapRef.setCenter([response.data.lon, response.data.lat]);
                    self.state.markerRef.setLngLat([response.data.lon, response.data.lat]);
                });
        }
    }

    onMapRefChange = node => {
        this.setState({mapRef: node});
    };

    onMarkerRefChange = node => {
        this.setState({markerRef: node});
    };

    onMarkerDragEnd = event => {
        var self = this;
        this.setState({
            circle: circle([event.lngLat.lng, event.lngLat.lat], self.state.radius, {steps: 50, units: 'meters', properties: {foo: 'bar'}}),
            lat: event.lngLat.lat,
            lon: event.lngLat.lng
        }, function () {
            self.state.markerRef.setLngLat([event.lngLat.lng, event.lngLat.lat]);
        });
    }

    changeRadius = (radius) => {
        var self = this;
        this.setState({
            circle: circle([this.state.lon, this.state.lat], radius, {steps: 50, units: 'meters', properties: {foo: 'bar'}}),
            radius: radius
        }, function () {
            self.state.markerRef.setLngLat([this.state.lon, this.state.lat]);
        });
    }

    SaveLocation = async event => {
        var self = this;
        axios.defaults.withCredentials = true;
        axios.post(API_ROOT + '/location', {
            name: this.state.name,
            address: this.state.address,
            city: this.state.city,
            postal: this.state.postal,
            province: this.state.province,
            country: this.state.country,
            lon: this.state.lon,
            lat: this.state.lat,
            radius: this.state.radius
        })
            .then(function (response) {
                console.log('response')
                self.setState({
                    modal: false
                });
                if (typeof self.props.location_saved != "undefined") {
                    self.props.location_saved(response.data.location_id, self.state.name, self.state.address, self.state.city);
                }
            })
            .catch(function (error) {
                if (typeof error.response != "undefined") {
                    if (error.response.status === 401) {
                        self.props.userSignOut()
                    } else {
                        Swal("Error", error.response.data.Message, "error");
                    }
                }
            });
    };

    render() {
        return (
            <>
                <div className={(typeof this.props.extraClass != "undefined" ? this.props.extraClass : "d-none")} onClick={this.toggle}>Create New Location</div>
                <div className={(typeof this.props.extraClass == "undefined" ? "mb-2 btn btn-primary btn-xs" : "d-none")} onClick={this.toggle}><i
                    className="fas fa-plus"></i> Location
                </div>
                <Modal isOpen={this.state.modal} fade={false} toggle={this.toggle} size="lg">
                    <ModalHeader>
                        <div className="h3 mb-0">Create New Location</div>
                    </ModalHeader>
                    <ModalBody>
                        <form className="form-horizontal">
                            <div className={(this.state.section != 1 ? "d-none" : "")}>
                                <Row className="mb-1">
                                    <label className="col-xl-3 col-form-label pt-0">Name / Description</label>
                                    <div className="col-xl-9">
                                        <Input size="sm" type="text" name="name" value={this.state.name} required onChange={this.updateValue}/>
                                    </div>
                                </Row>
                                <Row className="mb-1">
                                    <label className="col-xl-3 col-form-label pt-0">Address</label>
                                    <div className="col-xl-9">
                                        <Input size="sm" type="text" name="address" value={this.state.address} required onChange={this.updateValue}/>
                                    </div>
                                </Row>
                                <Row className="mb-1">
                                    <label className="col-xl-3 col-form-label pt-0">City</label>
                                    <div className="col-xl-9">
                                        <Input size="sm" type="text" name="city" value={this.state.city} required onChange={this.updateValue}/>
                                    </div>
                                </Row>
                                <Row className="mb-1">
                                    <label className="col-xl-3 col-form-label pt-0">Province / State</label>
                                    <div className="col-xl-9">
                                        <UncontrolledButtonDropdown>
                                            <DropdownToggle color="secondary" size="sm" caret style={{fontSize: '13px', width: 'auto'}}>
                                                {this.state.province}
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                {['NB', 'NS', 'PE', 'NL', 'ON', 'QC', 'SK', 'MB', 'AB', 'BC'].map((province, i) =>
                                                    <DropdownItem key={province} onClick={() => this.changeProvince(province)}>{province}</DropdownItem>
                                                )}
                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown>
                                    </div>
                                </Row>
                                <Row className="mb-1">
                                    <label className="col-xl-3 col-form-label pt-0">Postal Code</label>
                                    <div className="col-xl-9">
                                        <Input size="sm" type="text" name="postal" value={this.state.postal} onChange={this.updateValue}/>
                                    </div>
                                </Row>
                                <Row className="mb-1">
                                    <label className="col-xl-3 col-form-label pt-0">Country Code (2 digit)</label>
                                    <div className="col-xl-9">
                                        <Input size="sm" type="text" name="country" value={this.state.country} onChange={this.updateValue}/>
                                    </div>
                                </Row>
                                <div className="text-center">
                                    <div className="btn btn-primary" onClick={this.searchAddress}>Show location on map</div>
                                </div>
                            </div>
                            <div className={(this.state.section != 2 ? "d-none" : "")}>
                                <div className="h6">
                                    <b>{this.state.name}</b>: {this.state.address}, {this.state.city}, {this.state.province}, {this.state.postal}
                                    <button type="text" className="btn btn-xs btn-primary" name="section" value="1" onClick={this.updateValue}>Edit</button>
                                </div>
                                <div className="alert alert-info p-2">Drag the marker to the exact location, then set the geofence range to indicate when an asset is to be
                                    considered at that location
                                </div>
                                <div style={{height: '400px', width: '100%', position: 'relative'}}>
                                    <Map
                                        ref={this.onMapRefChange}
                                        initialViewState={{
                                            longitude: 0,
                                            latitude: 0,
                                            zoom: 16,
                                            bearing: 0,
                                            pitch: 0,
                                            touchZoomRotate: true,
                                            dragRotate: false,
                                            showCompass: false,
                                            visualizePitch: false,
                                            attributionControl: false,
                                        }}
                                        mapStyle="https://api.maptiler.com/maps/streets-v2/style.json?key=6P8HshICDGDXoVd9nuYs"
                                    >
                                        <NavigationControl position="top-right" showCompass={false} visualizePitch={false}/>
                                        <ScaleControl/>
                                        <Marker
                                            ref={this.onMarkerRefChange}
                                            longitude={-100}
                                            latitude={40}
                                            anchor="bottom"
                                            draggable
                                            onDragEnd={this.onMarkerDragEnd}
                                        >
                                            <Pin size={20}/>
                                        </Marker>
                                        <Source id="my-data" type="geojson" data={this.state.circle}>
                                            <Layer
                                                id="point-90-hi"
                                                type="fill"
                                                paint={{
                                                    "fill-color": "#088",
                                                    "fill-opacity": 0.4,
                                                    "fill-outline-color": "yellow"
                                                }}
                                            />
                                        </Source>
                                    </Map>
                                    <div style={{position: "absolute", top: "0px"}}>
                                        <UncontrolledButtonDropdown>
                                            <DropdownToggle color="secondary" caret style={{fontSize: '13px', width: 'auto'}}>
                                                Geofence: {this.state.radius}m
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                {Object.keys(this.state.radius_options).map((key, i) => {
                                                    return (
                                                        <DropdownItem key={key}
                                                                      onClick={() => this.changeRadius(this.state.radius_options[key])}>{this.state.radius_options[key]}m</DropdownItem>
                                                    )
                                                })}
                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown>
                                    </div>
                                </div>
                                <div className="text-center pt-2">
                                    <div className="btn btn-primary" onClick={this.SaveLocation}>
                                        Save Location
                                    </div>
                                </div>
                            </div>
                        </form>
                    </ModalBody>
                </Modal>
            </>
        );
    };
}

export default (AddLocation);
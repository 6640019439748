import React from 'react';
import ContentWrapper from '../Layout/ContentWrapper';
import {Button, InputGroup, DropdownItem, DropdownToggle, DropdownMenu, UncontrolledButtonDropdown} from 'reactstrap';
import axios from 'axios';
import {API_ROOT} from '../../api-config';
import Swal from 'sweetalert2'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import 'moment-timezone';
import getDrivers, {getDriversLogged} from "./DriverFunctions";
import {getEquipment} from "../Equipment/EquipmentFunctions";


moment.tz.setDefault("America/Halifax");

var money = require("money-math");

class DriversList extends React.Component {
    constructor(props) {
        super(props);

        this.updateDates = this.updateDates.bind(this);
        this.goToDriver = this.goToDriver.bind(this);

        this.state = {
            drivers: {},
            drivers_sort: [],
            loading: true,
            staff: [],
            equipment: [],
            drivers_logged: {},
            startDate: (localStorage.getItem('acc_startDate') != null ? moment(localStorage.getItem('acc_startDate')) : moment("01 Jan 2023")),
            endDate: (localStorage.getItem('acc_endDate') != null ? moment(localStorage.getItem('acc_endDate')) : moment().endOf('day')),
            drivers_statuses: {1: "In Service", 2: "Out of Service", 0: "Retired"},
            ranges: {
                'Today': [moment(), moment()],
                'Last 7 Days': [moment().subtract(6, 'days'), moment().endOf('day')],
                'Last 30 Days': [moment().subtract(29, 'days'), moment().endOf('day')],
                'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                'All Time': [moment("01 Jan 2023"), moment().endOf('day')]
            },
            loadanyway: false,
            sort: (localStorage.getItem('sort') != null ? localStorage.getItem('sort') : "name"),
            sort_order: (localStorage.getItem('sort_order') != null ? localStorage.getItem('sort_order') * -1 : -1),
        };
    }

    componentDidMount() {
        document.title = "Drivers | Greenhaven Transportation";
        var self = this;
        getEquipment(function (equipment) {
            getDrivers(function (drivers) {
                getDriversLogged(function (drivers_logged) {
                    if (Object.keys(drivers).length > 0) {
                        console.log(drivers)
                        self.setState({
                            drivers: drivers,
                            loading: false,
                            equipment: equipment,
                            drivers_logged: drivers_logged
                        },);
                        self.onSort(null, self.state.sort)
                    }
                });
            });
        });
    }

    // on date range change
    updateDates(event, picker) {
        if (event.type == "apply") {
            localStorage.setItem('acc_startDate', picker.startDate);
            localStorage.setItem('acc_endDate', picker.endDate);
            this.setState({
                startDate: picker.startDate,
                endDate: picker.endDate
            });
        }
    }

    changeEquipmentStatus = (drivers_status) => {
        if (drivers_status == null) {
            var drivers_status_name = "All"
        } else {
            var drivers_status_name = this.state.drivers_statuses[drivers_status]
        }
        localStorage.setItem('drivers_status', drivers_status);
        localStorage.setItem('drivers_status_name', drivers_status_name);
        this.setState({
            drivers_status: drivers_status,
            drivers_status_name: drivers_status_name
        });
    };

    goToDriver = (driver_id) => {
        this.props.history.push("/drivers/" + driver_id);
    };

    onSort(event, sortKey) {
        localStorage.setItem('sort', sortKey);
        localStorage.setItem('sort_order', this.state.sort_order);
        console.log('this.state.drivers')
        console.log(this.state.drivers)
        var sort_order = this.state.sort_order * -1;
        if (sortKey != this.state.sort) {
            sort_order = 1;
        }

        function format_search_val(val) {
            if (isNaN(val)) {
                return val.toLowerCase();
            } else {
                return parseFloat(val);
            }
        }

        var drivers_sort = [];
        for (const driver_id of Object.keys(this.state.drivers)) {
            var obj = this.state.drivers[driver_id];
            obj['name'] = obj.first_name + ' ' + obj.last_name;
            drivers_sort.push(obj)
        }
        console.log(drivers_sort)
        var drivers = drivers_sort.sort((a, b) => {
            if (typeof format_search_val(a[sortKey]) == "string" && typeof format_search_val(b[sortKey]) != "string") {
                return (sort_order == -1 ? -1 : 1);
            } else {
                if (format_search_val(a[sortKey]) > format_search_val(b[sortKey])) {
                    return (sort_order == -1 ? -1 : 1);
                }
                if (format_search_val(a[sortKey]) < format_search_val(b[sortKey])) {
                    return (sort_order == -1 ? 1 : -1);
                }
            }
            return 0;
        });
        this.setState({
            sort: sortKey,
            sort_drivers: sort_order,
            drivers_sort: drivers
        });
        console.log('drivers_sort')
        console.log(drivers)
        console.log(sort_order)
    }

    render() {

        // date range selector variables
        let start = this.state.startDate.format('MMM. D/YY');
        let end = this.state.endDate.format('MMM. D/YY');
        let label = start + ' - ' + end;
        if (start === end) {
            label = start;
        }

        return (
            <ContentWrapper>
                <div className="content-heading">
                    <div>Drivers</div>
                </div>
                <div className="mb-3 form-inline" style={{zIndex: 1000}}>
                    <DateRangePicker
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        ranges={this.state.ranges}
                        maxDate={moment()}
                        onEvent={this.updateDates}
                        autoUpdateInput={true}
                    >
                        <button type="button" className="btn btn-primary selected-date-range-btn mr-1">
                            Summary Period:* <b>{label}</b> <span className="fas fa-caret-down fa-sm"></span>
                        </button>
                    </DateRangePicker>
                    <InputGroup className="mr-1">
                        <UncontrolledButtonDropdown>
                            <DropdownToggle caret color={(String(this.state.drivers_status) == "1" ? "primary" : "warning")} style={{fontSize: '13px', width: 'auto'}}>
                                Status: <b>{this.state.drivers_status_name}</b>
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem key="null" onClick={() => this.changeEquipmentStatus(null)}>All</DropdownItem>
                                <DropdownItem divider/>
                                {Object.keys(this.state.drivers_statuses).map((key, i) => {
                                        if (typeof this.state.drivers_statuses[key] != "undefined") {
                                            return (<DropdownItem key={key} onClick={() => this.changeEquipmentStatus(key)}>{this.state.drivers_statuses[key]}</DropdownItem>)
                                        }
                                    }
                                )}
                            </DropdownMenu>
                        </UncontrolledButtonDropdown>
                    </InputGroup>
                </div>
                <div className={(this.state.loading ? "card card-default whirl traditional" : "card card-default")}>
                    <div>
                        <div className="card-header">
                            <div className="card-title">{this.state.drivers_sort.length} drivers</div>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className={(this.state.drivers_sort.length > 0 ? "table table-hover table-pointer text-right" : "d-none")}>
                                    <thead>
                                    <tr>
                                        <th className="text-left" onClick={e => this.onSort(e, 'name')}><i className="fas fa-sort"></i> Name</th>
                                        <th className="text-left" onClick={e => this.onSort(e, 'name')}><i className="fas fa-sort"></i> CDL Jurisdiction</th>
                                        <th className="text-left" onClick={e => this.onSort(e, 'name')}><i className="fas fa-sort"></i> Employment</th>
                                        <th className="text-left"> Status</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {Object.entries(this.state.drivers_sort).map(([key, drivers]) => {
                                        return (
                                            <tr key={key} onClick={this.goToDriver.bind(this, drivers.driver_id)}>
                                                <td className="text-left">{drivers.name}</td>
                                                <td className="text-right">{drivers.cdl_jurisdiction}</td>
                                                <td className="text-right">{drivers.status}</td>
                                                <td className="text-left">{(typeof this.state.drivers_logged[drivers.driver_id] != "undefined" ? "Logged into truck #" + this.state.equipment[this.state.drivers_logged[drivers.driver_id].equipment_id].name + " since " + moment(this.state.drivers_logged[drivers.driver_id].in_time).format('HH:mm') : "")}</td>
                                            </tr>
                                        )
                                    })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        );
    }
}

export default (DriversList);
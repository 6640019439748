import React, {Component} from 'react';
import ContentWrapper from '../Layout/ContentWrapper';
import {Link} from 'react-router-dom';
import {
    Card,
    CardBody,
    Col,
    Row,
    Input,
    Button,
    UncontrolledButtonDropdown,
    DropdownMenu,
    DropdownToggle,
    DropdownItem,
    FormGroup, InputGroupAddon, InputGroup
} from 'reactstrap';
import axios from 'axios';
import {API_ROOT} from '../../api-config';
import Swal from 'sweetalert2'
import moment from 'moment';
import SingleDatePicker from 'react-bootstrap-daterangepicker';
import 'moment-timezone';

import getCustomers from '../Customers/CustomerFunctions';

moment.tz.setDefault("America/Halifax");

var money = require("money-math");

class InvoicePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            invoice: {
                po: null,
                invoice_date: moment(),
                net_days: 30,
                notes_internal: "",
                notes_external: "",
                loads: [],
                manual_items: [],
                customer_id: null
            },
            customers: {},
        };
        this.updateValue = this.updateValue.bind(this);
    }

    componentDidMount() {
        document.title = "Invoice #" + this.props.match.params.invoice_id + " | Greenhaven Transportation";
        var self = this;
        axios.defaults.withCredentials = true;
        axios.get(API_ROOT + '/invoice/' + self.props.match.params.invoice_id)
            .then(function (response) {
                getCustomers(function (customers) {
                    self.setState({
                            customers: customers,
                            invoice: response.data,
                            loading: false,
                        }
                    );
                });
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    self.props.userSignOut()
                } else {
                    Swal("Error", error.response.data.Message, "error");
                }
            });
    }

    updateValue(event, name, value) {
        if (event.target) {
            event.preventDefault();
            event.stopPropagation();
        }
        if (typeof name == "undefined") {
            name = event.target.name
        }
        if (typeof value == "undefined") {
            value = event.target.value
        }
        this.setState({
            [name]: value
        });
    }

    deleteInvoice = () => {
        var self = this;
        axios.defaults.withCredentials = true;
        Swal.fire({
            title: 'Are you sure?',
            text: "This invoice will be permanently deleted and cannot be restored. Any attached loads will be set back to 'Completed' status.",
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it',
            cancelButtonText: 'Cancel',
            type: 'warning'
        }).then((result) => {
            if (result.value) {
                axios.delete(API_ROOT + '/invoice/' + self.props.match.params.invoice_id)
                    .then(function (response) {
                        self.props.history.push("/invoices");
                    })
                    .catch(function (error) {
                        Swal("Error", error.response.data.Message, "error");
                    });
            }
        });
    }


    render() {
        var subtotal_loads = money.floatToAmount(0);
        var tax_loads = money.floatToAmount(0);
        var total_loads = money.floatToAmount(0);
        var subtotal_manual = money.floatToAmount(0);
        var tax_manual = money.floatToAmount(0);
        var total_manual = money.floatToAmount(0);
        var miles = 0;
        return (
            <ContentWrapper>
                <div className="content-heading">
                    <div>
                        <Link to="../invoices">Invoices</Link>&nbsp;/&nbsp; Invoice # {this.props.match.params.invoice_id}
                    </div>
                </div>
                <Card className="card card-default">
                    <CardBody>
                        <form className="form-horizontal">
                            <div className="row">
                                <Col>
                                    <FormGroup row>
                                        <Col lg="4" sm="6" className="text-bold">Invoice Date:</Col>
                                        <Col lg="8" sm="6">
                                            {moment(this.state.invoice.invoice_date).format('MMM. D, YYYY')}
                                        </Col>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup row>
                                        <Col lg="4" sm="6" className="text-bold">Customer:</Col>
                                        <Col lg="8" sm="6">
                                            {(typeof this.state.customers[this.state.invoice.customer_id] == "undefined" ? "-" : this.state.customers[this.state.invoice.customer_id].name)}
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </div>
                            <div className="row">
                                <Col>
                                    <FormGroup row>
                                        <Col lg="4" sm="6" className="text-bold">Due Date:</Col>
                                        <Col lg="8" sm="6">
                                            {moment(this.state.invoice.invoice_date).add(this.state.invoice.net_days, 'days').format('MMM. D, YYYY')} (Net {this.state.invoice.net_days})
                                        </Col>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup row>
                                        <Col lg="4" sm="6" className="text-bold">PO #:</Col>
                                        <Col lg="8" sm="6">
                                            {this.state.invoice.po}
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </div>
                            <div className="row">
                                <Col>
                                    <FormGroup row>
                                        <Col lg="4" sm="6" className="text-bold">Notes to Customer:</Col>
                                        <Col lg="8" sm="6">
                                            {this.state.invoice.notes_external}
                                        </Col>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup row>
                                        <Col lg="4" sm="6" className="text-bold">Internal Notes:</Col>
                                        <Col lg="8" sm="6">
                                            {this.state.invoice.notes_internal}
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </div>
                            <FormGroup row className="pt-2 pb-2">
                                <Col lg="2" sm="3" className="text-bold">Loads:</Col>
                                <Col lg="10" sm="9">
                                    <table
                                        className={(this.state.invoice.customer_id == null || Object.keys(this.state.invoice.loads).length == 0 ? "d-none" : "table table-sm table-hover")}>
                                        <thead>
                                        <tr className="thead-light">
                                            <th>Load #</th>
                                            <th>Date</th>
                                            <th className="text-right">Miles</th>
                                            <th className="text-right">Subtotal</th>
                                            <th className="text-right">Tax</th>
                                            <th className="text-right">Total</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {this.state.invoice.loads.map((load, key) => {
                                            var load_id = load.load_id;
                                            subtotal_loads = money.add(subtotal_loads, money.floatToAmount(load.total_subtotal));
                                            tax_loads = money.add(tax_loads, money.floatToAmount(load.total_tax));
                                            total_loads = money.add(total_loads, money.floatToAmount(load.total_total));
                                            miles = (miles + load.miles);
                                            return (
                                                <tr key={key} onClick={() => this.toggleCheck(load_id)}>
                                                    <td className={(load.checked == 1 ? "bg-light" : "")}>
                                                        <Link to={"../loads/" + load_id}>
                                                            {load_id}
                                                        </Link>
                                                    </td>
                                                    <td className={(load.checked == 1 ? "bg-light" : "")}>
                                                        {moment(load.start).format('MMM. D, YYYY')}
                                                    </td>
                                                    <td className={(load.checked == 1 ? "bg-light text-right" : "text-right")}>
                                                        {money.format("USD", money.floatToAmount(load.miles))}
                                                    </td>
                                                    <td className={(load.checked == 1 ? "bg-light text-right" : "text-right")}>
                                                        ${money.format("USD", money.floatToAmount(load.total_subtotal))}
                                                    </td>
                                                    <td className={(load.checked == 1 ? "bg-light text-right" : "text-right")}>
                                                        ${money.format("USD", money.floatToAmount(load.total_tax))}
                                                    </td>
                                                    <td className={(load.checked == 1 ? "bg-light text-right" : "text-right")}>
                                                        ${money.format("USD", money.floatToAmount(load.total_total))}
                                                    </td>
                                                </tr>)
                                        }, this)}
                                        <tr>
                                            <th colSpan="2" className="text-right">
                                                Total:
                                            </th>
                                            <th className="text-right">
                                                {money.format("USD", money.floatToAmount(miles))}
                                            </th>
                                            <th className="text-right">
                                                ${money.format("USD", subtotal_loads)}
                                            </th>
                                            <th className="text-right">
                                                ${money.format("USD", tax_loads)}
                                            </th>
                                            <th className="text-right">
                                                <b>${money.format("USD", total_loads)}</b>
                                            </th>
                                        </tr>
                                        </tbody>
                                    </table>
                                </Col>
                            </FormGroup>
                            <Row className="pt-2 pb-2">
                                <Col lg="2" sm="3" className="text-bold">Manual Item(s)</Col>
                                <Col lg="10" sm="9">
                                    <table className="table table-sm">
                                        <thead>
                                        <tr className="thead-light">
                                            <th>Description</th>
                                            <th className="text-right">Subtotal</th>
                                            <th className="text-right">Tax</th>
                                            <th className="text-right">Total</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {this.state.invoice.manual_items.map((line, key) => {
                                            console.log(line)
                                            var total = money.add(money.floatToAmount(line.subtotal), money.floatToAmount(line.tax));
                                            subtotal_manual = money.add(subtotal_manual, money.floatToAmount(line.subtotal));
                                            tax_manual = money.add(tax_manual, money.floatToAmount(line.tax));
                                            total_manual = money.add(total_manual, total);
                                            return (
                                                <tr key={key}>
                                                    <td>
                                                        {line.description}
                                                    </td>
                                                    <td className="text-right">
                                                        ${money.format("USD", money.floatToAmount(line.subtotal))}
                                                    </td>
                                                    <td className="text-right">
                                                        ${money.format("USD", money.floatToAmount(line.tax))}
                                                    </td>
                                                    <td className="text-right">
                                                        ${money.format("USD", money.floatToAmount(line.total))}
                                                    </td>
                                                </tr>)
                                        }, this)}
                                        </tbody>
                                        <tfoot>
                                        <tr>
                                            <th className="text-right">
                                                Manual Total:
                                            </th>
                                            <th className="text-right">${money.format("USD", subtotal_manual)}</th>
                                            <th className="text-right">${money.format("USD", tax_manual)}</th>
                                            <th className="text-right">${money.format("USD", total_manual)}</th>
                                        </tr>
                                        </tfoot>
                                    </table>
                                </Col>
                            </Row>
                            <Row className="pt-3 pb-3">
                                <Col lg="2" sm="3" className="text-bold">Invoice Total:</Col>
                                <Col lg="10" sm="9">
                                    <Row><Col sm="4" md="3" lg="2">Subtotal:</Col><Col>${money.format("USD", money.add(subtotal_loads, subtotal_manual))}</Col></Row>
                                    <Row><Col sm="4" md="3" lg="2">Tax:</Col><Col>${money.format("USD", money.add(tax_loads, tax_manual))}</Col></Row>
                                    <Row className="text-bold text-danger"><Col sm="3" md="2">Total
                                        Due:</Col><Col>${money.format("USD", money.add(total_loads, total_manual))}</Col></Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="2" sm="3"></Col>
                                <Col lg="10" sm="9">
                                    <Button color="primary"><i className="far fa-paper-plane"></i> Email</Button>
                                    <Button color="primary" className="ml-1"><i className="fas fa-file-download"></i> PDF</Button>
                                    <Button color="danger" size="sm" className="float-right" onClick={this.deleteInvoice}><i className="far fa-trash-alt"></i> Delete
                                        Invoice</Button>
                                </Col>
                            </Row>
                        </form>
                    </CardBody>
                </Card>
            </ContentWrapper>
        );
    }
}

export default InvoicePage;

import React from 'react';
import {Link} from 'react-router-dom';
import ContentWrapper from '../Layout/ContentWrapper';
import {Button, DropdownItem, DropdownToggle, DropdownMenu, UncontrolledButtonDropdown} from 'reactstrap';
import axios from 'axios';
import {API_ROOT} from '../../api-config';
import Swal from 'sweetalert2'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import 'moment-timezone';
import getDrivers from "../Drivers/DriverFunctions";
import getCustomers from "../Customers/CustomerFunctions";
import getLocations from "../Locations/LocationFunctions";
import {getEquipment} from "../Equipment/EquipmentFunctions";
import money from "money-math";

moment.tz.setDefault("America/Halifax");

class LoadsList extends React.Component {
    constructor(props) {
        super(props);

        this.updateDates = this.updateDates.bind(this);
        this.goToLoad = this.goToLoad.bind(this);

        this.state = {
            equipment: {},
            equipment_sort: [],
            customers: {},
            customers_sort: [],
            loading: true,
            loads: {},
            loads_sort: [],
            locations: [],
            locations_sort: {},
            customer_id: null,
            vehicle_id: null,
            origin: null,
            destination: null,
            driver_id: null,
            drivers: {},
            drivers_sort: [],
            startDate: (localStorage.getItem('acc_startDate') != null ? moment(localStorage.getItem('acc_startDate')) : moment().endOf('day')),
            endDate: (localStorage.getItem('acc_endDate') != null ? moment(localStorage.getItem('acc_endDate')) : moment().add(6, 'days')),
            ranges: {
                'Today': [moment(), moment()],
                'Next 7 Days': [moment().add(6, 'days'), moment().endOf('day')],
                'Next 30 Days': [moment().add(30, 'days'), moment().endOf('day')],
                'Last 7 Days': [moment().subtract(6, 'days'), moment().endOf('day')],
                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                'All Time': [moment("01 Jan 2023"), moment("31 Dec 2024")]
            },
            sort: (localStorage.getItem('sort2') != null ? localStorage.getItem('sort2') : "load_id"),
            sort_order: (localStorage.getItem('sort_order2') != null ? localStorage.getItem('sort_order2') * 1 : -1),
            status: null,
            statuses: {0: "Draft", 1: "Scheduled", 2: "Completed", 3: "Billed"}
        };
    }

    componentDidMount() {
        document.title = "Loads | Greenhaven Transportation";
        var self = this;
        getCustomers(function (customers) {
            getDrivers(function (drivers) {
                getEquipment(function (equipment) {
                    getLocations(function (location_response) {
                        var drivers_sort = [];
                        for (const driver_id of Object.keys(drivers)) {
                            drivers_sort.push(drivers[driver_id]);
                        }
                        var drivers_sort = drivers_sort.sort(function (a, b) {
                            if (a.first_name < b.first_name) {
                                return -1
                            } else {
                                return 1
                            }
                        });
                        var customers_sort = [];
                        console.log(customers)
                        for (const customer_id of Object.keys(customers)) {
                            customers_sort.push(customers[customer_id]);
                            console.log(customers[customer_id])
                        }
                        var customers_sort = customers_sort.sort(function (a, b) {
                            if (a.name < b.name) {
                                return -1
                            } else {
                                return 1
                            }
                        });
                        self.setState({
                            customers: customers,
                            equipment: equipment,
                            locations: location_response.locations,
                            locations_sort: location_response.locations_sort,
                            drivers: drivers,
                            drivers_sort: drivers_sort,
                            customers_sort: customers_sort,
                        }, function () {
                            this.update_load();
                        });
                    });
                });
            });
        });
    }

    update_load() {
        var self = this;
        axios.defaults.withCredentials = true;
        axios.get(API_ROOT + '/loads/?customer_id=' + self.state.customer_id + "&vehicle_id=" + self.state.vehicle_id + "&driver_id=" + self.state.driver_id + "&equipment_id=" + self.state.equipment_id + "&origin=" + self.state.origin + "&destination=" + self.state.destination + "&startDate=" + self.state.startDate + "&endDate=" + self.state.endDate + "&status=" + self.state.status)
            .then(function (response) {
                self.setState({
                        loads: response.data,
                        loading: false,
                    }
                );
                self.onSort(null, self.state.sort)
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    self.props.userSignOut()
                } else {
                    Swal("Error", error.response.data.Message, "error");
                }
            });
    }

    // on date range change
    updateDates(event, picker) {
        if (event.type == "apply") {
            localStorage.setItem('acc_startDate', picker.startDate);
            localStorage.setItem('acc_endDate', picker.endDate);
            this.setState({
                startDate: picker.startDate,
                endDate: picker.endDate
            }, function () {
                this.update_load()
            });
        }
    }

    changeDriver = (driver_id) => {
        this.setState({
            driver_id: driver_id,
        }, function () {
            this.update_load()
        });
    };

    changeCustomer = (customer_id) => {
        this.setState({
            customer_id: customer_id,
        }, function () {
            this.update_load()
        });
    };

    updateValue(event, name, value) {
        if (event.target) {
            event.preventDefault();
            event.stopPropagation();
        }
        if (typeof name == "undefined") {
            name = event.target.name
        }
        if (typeof value == "undefined") {
            value = event.target.value
        }
        this.setState({
            [name]: value
        }, function () {
            this.update_load()
        });
    }

    goToLoad = (load_id) => {
        this.props.history.push("/loads/" + load_id);
    };

    onSort(event, sortKey) {
        localStorage.setItem('sort2', sortKey);
        localStorage.setItem('sort_order2', this.state.sort_order);
        var sort_order = this.state.sort_order * -1;
        if (sortKey != this.state.sort) {
            sort_order = 1;
        }

        function format_search_val(val) {
            if (typeof val == "undefined") {
                return 1;
            } else if (isNaN(val)) {
                return val.toLowerCase();
            } else {
                return parseFloat(val);
            }
        }

        var loads_sort = [];
        for (const load_id of Object.keys(this.state.loads)) {
            console.log(this.state.loads[load_id])
            loads_sort.push(this.state.loads[load_id])
        }
        var loads = loads_sort.sort((a, b) => {
            if (typeof format_search_val(a[sortKey]) == "string" && typeof format_search_val(b[sortKey]) != "string") {
                return (sort_order == -1 ? -1 : 1);
            } else {
                if (format_search_val(a[sortKey]) > format_search_val(b[sortKey])) {
                    return (sort_order == -1 ? -1 : 1);
                }
                if (format_search_val(a[sortKey]) < format_search_val(b[sortKey])) {
                    return (sort_order == -1 ? 1 : -1);
                }
            }
            return 0;
        });
        this.setState({
            sort: sortKey,
            sort_equipment: sort_order,
            loads_sort: loads
        });
    }

    render() {

        // date range selector variables
        let start = this.state.startDate.format('MMM. D/YY');
        let end = this.state.endDate.format('MMM. D/YY');
        let label = start + ' - ' + end;
        if (start === end) {
            label = start;
        }

        return (
            <ContentWrapper>
                <div className="content-heading">
                    <div>Loads</div>
                    <div className="ml-auto">
                        <Link to="loads/create" className="btn btn-primary"><i className="fas fa-plus"></i> Load</Link>
                    </div>
                </div>
                <div className="mb-3 form-inline" style={{zIndex: 1000}}>
                    <DateRangePicker
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        ranges={this.state.ranges}
                        maxDate={moment()}
                        onEvent={this.updateDates}
                        autoUpdateInput={true}
                    >
                        <button type="button" className="btn btn-primary selected-date-range-btn mr-1">
                            Summary Period: <b>{label}</b> <span className="fas fa-caret-down fa-sm"></span>
                        </button>
                    </DateRangePicker>
                    <UncontrolledButtonDropdown className="mr-1">
                        <DropdownToggle color="primary" caret style={{fontSize: '13px', width: 'auto'}}>
                            {(this.state.driver_id == null ? "All Drivers" : this.state.drivers[this.state.driver_id].first_name)} {(this.state.driver_id == null ? "" : this.state.drivers[this.state.driver_id].last_name)}
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem key="null" onClick={() => this.updateValue(false, 'driver_id', null)}>All Drivers</DropdownItem>
                            <DropdownItem divider/>
                            {Object.keys(this.state.drivers_sort).map((key, i) => {
                                return (<DropdownItem key={key}
                                                      onClick={() => this.updateValue(false, 'driver_id', this.state.drivers_sort[key].driver_id)}>{this.state.drivers_sort[key].first_name} {this.state.drivers_sort[key].last_name}</DropdownItem>)
                            })}
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                    <UncontrolledButtonDropdown className="mr-1">
                        <DropdownToggle color="primary" caret style={{fontSize: '13px', width: 'auto'}}>
                            {(this.state.customer_id == null ? "All Customers" : this.state.customers[this.state.customer_id].name)}
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem key="null" onClick={() => this.updateValue(false, 'customer_id', null)}>All Customers</DropdownItem>
                            <DropdownItem divider/>
                            {Object.keys(this.state.customers_sort).map((key, i) => {
                                return (<DropdownItem key={key}
                                                      onClick={() => this.updateValue(false, 'customer_id', this.state.customers_sort[key].customer_id)}>{this.state.customers_sort[key].name}</DropdownItem>)
                            })}
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                    <UncontrolledButtonDropdown className="mr-1">
                        <DropdownToggle color="primary" caret style={{fontSize: '13px', width: 'auto'}}>
                            {(this.state.origin == null ? "All Start Points" : this.state.locations[this.state.origin].name)}
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem key="null" onClick={() => this.updateValue(false, 'origin', null)}>All Start Points</DropdownItem>
                            <DropdownItem divider/>
                            {Object.keys(this.state.locations_sort).map((key, i) => {
                                return (<DropdownItem key={key}
                                                      onClick={() => this.updateValue(false, 'origin', this.state.locations_sort[key].location_id)}>{this.state.locations_sort[key].name}</DropdownItem>)
                            })}
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                    <UncontrolledButtonDropdown className="mr-1">
                        <DropdownToggle color="primary" caret style={{fontSize: '13px', width: 'auto'}}>
                            {(this.state.destination == null ? "All End Points" : this.state.locations[this.state.destination].name)}
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem key="null" onClick={() => this.updateValue(false, 'destination', null)}>All End Points</DropdownItem>
                            <DropdownItem divider/>
                            {Object.keys(this.state.locations_sort).map((key, i) => {
                                return (<DropdownItem key={key}
                                                      onClick={() => this.updateValue(false, 'destination', this.state.locations_sort[key].location_id)}>{this.state.locations_sort[key].name}</DropdownItem>)
                            })}
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                    <UncontrolledButtonDropdown className="mr-1">
                        <DropdownToggle color="primary" caret style={{fontSize: '13px', width: 'auto'}}>
                            {(this.state.status == null ? "All Statuses" : this.state.statuses[this.state.status])}
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem key="null" onClick={() => this.updateValue(false, 'status', null)}>All Statuses</DropdownItem>
                            <DropdownItem divider/>
                            {Object.keys(this.state.statuses).map((key, i) => {
                                return (<DropdownItem key={key} onClick={() => this.updateValue(false, 'status', key)}>{this.state.statuses[key]}</DropdownItem>)
                            })}
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                </div>
                <div className={(this.state.loading ? "card card-default whirl traditional" : "card card-default")}>
                    <div>
                        <div className="card-header">
                            <div className="card-title">{this.state.loads_sort.length} Loads</div>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className={(this.state.loads_sort.length > 0 ? "table table-hover table-pointer text-right" : "d-none")}>
                                    <thead>
                                    <tr>
                                        <th className="text-left" onClick={e => this.onSort(e, 'name')}><i className="fas fa-sort"></i> Load #</th>
                                        <th className="text-left" onClick={e => this.onSort(e, 'name')}><i className="fas fa-sort"></i> Alerts</th>
                                        <th className="text-left" onClick={e => this.onSort(e, 'name')}><i className="fas fa-sort"></i> Start Time</th>
                                        <th className="text-left" onClick={e => this.onSort(e, 'name')}><i className="fas fa-sort"></i> End Time</th>
                                        <th className="text-left" onClick={e => this.onSort(e, 'name')}><i className="fas fa-sort"></i> Start Point</th>
                                        <th className="text-left" onClick={e => this.onSort(e, 'name')}><i className="fas fa-sort"></i> End Point</th>
                                        <th className="text-left" onClick={e => this.onSort(e, 'make_model')}><i className="fas fa-sort"></i> Customer</th>
                                        <th className="text-left" onClick={e => this.onSort(e, 'status')}><i className="fas fa-sort"></i> Driver</th>
                                        <th className="text-left" onClick={e => this.onSort(e, 'use')}><i className="fas fa-sort"></i> Truck</th>
                                        <th className="text-right" onClick={e => this.onSort(e, 'odo')}><i className="fas fa-sort"></i> Equipment</th>
                                        <th className="text-right" onClick={e => this.onSort(e, 'total_margin')}><i className="fas fa-sort"></i> Margin</th>
                                        <th className="text-right" onClick={e => this.onSort(e, 'cost_maintenance')}><i className="fas fa-sort"></i> Status</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {Object.entries(this.state.loads_sort).map(([key, load]) => {
                                        var errors = JSON.parse(load.errors);
                                        var warnings = JSON.parse(load.warnings);
                                        return (
                                            <tr key={key} onClick={this.goToLoad.bind(this, load.load_id)}>
                                                <td className="text-left">{load.load_id}</td>
                                                <td className="text-left">
                                                    <div className={(errors.length > 0 ? "badge rounded-pill bg-danger ml-1" : "d-none")}>
                                                        <i className="fas fa-exclamation-triangle"></i> x{errors.length}
                                                    </div>
                                                    <div className={(warnings.length > 0 ? "badge rounded-pill bg-warning ml-1" : "d-none")}>
                                                        <i className="fas fa-exclamation-circle"></i> x{warnings.length}
                                                    </div>
                                                </td>
                                                <td className="text-left">{(load.start == null ? "--" : moment(load.start).format("MMM. D/YY HH:mm"))}</td>
                                                <td className="text-left">{(load.start == null ? "--" : moment(load.end).format("MMM. D/YY HH:mm"))}</td>
                                                <td className="text-left">{(typeof this.state.locations[load.origin] == "undefined" ? "" : this.state.locations[load.origin].name)}</td>
                                                <td className="text-left">{(typeof this.state.locations[load.destination] == "undefined" ? "" : this.state.locations[load.destination].name)}</td>
                                                <td className="text-left">{(typeof this.state.customers[load.customer_id] == "undefined" ? "" : this.state.customers[load.customer_id].name)}</td>
                                                <td className="text-left">{(typeof this.state.drivers[load.driver_id] == "undefined" ? "" : this.state.drivers[load.driver_id].first_name)}</td>
                                                <td className="text-left">{(typeof this.state.equipment[load.vehicle_id] == "undefined" ? "" : this.state.equipment[load.vehicle_id].name)}</td>
                                                <td className="text-left">{(typeof this.state.equipment[load.equipment_id] == "undefined" ? "" : this.state.equipment[load.equipment_id].name)}</td>
                                                <td className="text-right">{(load.total_margin == null ? "NA" : "$" + money.format("USD", money.floatToAmount(load.total_margin)))}</td>
                                                <td className="text-left">
                                                    <span className={(load.status != 0 ? "d-none" : "badge bg-light text-dark")}>DRAFT</span>
                                                    <span className={(load.status != 1 ? "d-none" : "badge bg-info")}>SCHEDULED</span>
                                                    <span className={(load.status != 2 ? "d-none" : "badge bg-success")}>COMPLETED</span>
                                                    <span className={(load.status != 3 ? "d-none" : "badge bg-dark")}>BILLED</span>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        );
    }
}

export default (LoadsList);
import React, {Component} from 'react';

import ToggleState from '../Common/ToggleState';

class Header extends Component {
    constructor(props) {
        super(props);
    }

    toggle = () => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    render() {
        return (
            <header className="topnavbar-wrapper">
                { /* START Top Navbar */}
                <nav className="navbar topnavbar">
                    { /* START navbar header */}
                    <div className="navbar-header">
                        <a className="navbar-brand" href="/dashboard">
                            <div className="brand-logo">
                                <img className="img-fluid" src="img/logo.svg" alt="Greenhaven Transportation"/>
                            </div>
                            <div className="brand-logo-collapsed">
                                <img className="img-fluid" src="img/logo-single.svg" alt="Greenhaven Transportation"/>
                            </div>
                        </a>
                    </div>
                    { /* END navbar header */}

                    { /* START Left navbar */}
                    <ul className="navbar-nav mr-auto flex-row">
                        <li className="nav-item">
                            { /* Button to show/hide the sidebar on mobile. Visible on mobile only. */}
                            <ToggleState state="aside-toggled" nopersist={true}>
                                <a href="" className="nav-link sidebar-toggle d-md-none">
                                    <em className="fas fa-bars"></em>
                                </a>
                            </ToggleState>
                        </li>
                    </ul>
                    { /* END Left navbar */}
                </nav>
                { /* END Top Navbar */}
            </header>
        );
    }

}

export default Header;

import React from "react";
import axios from 'axios';
import moment from 'moment';
import {API_ROOT} from '../../api-config';

export function getEquipment(response) {
    var fetch_data = true;
    var current_timestamp = Math.round((new Date()).getTime() / 1000);/*
    if (localStorage.getItem('vehicles') !== null) {
        var vehicles = JSON.parse(localStorage.getItem('vehicles'));
        if (vehicles.date_fetched>(current_timestamp-(60*60))) {
            fetch_data = false;
            response(vehicles.data);
        }
    }*/
    if (fetch_data) {
        axios.defaults.withCredentials = true;
        axios.get(`${API_ROOT}/equipment/`, {withCredentials: true})
            .then(function (ajax_response) {
                var vehicles = {};
                vehicles.data = {};
                vehicles.data = ajax_response.data;
                vehicles.date_fetched = current_timestamp;
                localStorage.setItem('equipment', JSON.stringify(vehicles));
                response(vehicles.data);
            })
            .catch(function (error) {
                response({});
            });
    }
}

export function getEquipmentTypes(response) {
    var fetch_data = true;
    var current_timestamp = Math.round((new Date()).getTime() / 1000);
    if (localStorage.getItem('equipment_types') !== null) {
        var vehicles = JSON.parse(localStorage.getItem('equipment_types'));
        if (vehicles.date_fetched > (current_timestamp - (60 * 60))) {
            fetch_data = false;
            response(vehicles.data);
        }
    }
    if (fetch_data) {
        axios.defaults.withCredentials = true;
        axios.get(`${API_ROOT}/equipment/types`, {withCredentials: true})
            .then(function (ajax_response) {
                var vehicles = {};
                vehicles.data = {};
                vehicles.data = ajax_response.data;
                vehicles.date_fetched = current_timestamp;
                localStorage.setItem('equipment_types', JSON.stringify(vehicles));
                response(vehicles.data);
            })
            .catch(function (error) {
                response({});
            });
    }
}

export function getEquipmentManufacturers(response) {
    var fetch_data = true;
    var current_timestamp = Math.round((new Date()).getTime() / 1000);
    if (localStorage.getItem('equipment_manufacturers') !== null) {
        var vehicles = JSON.parse(localStorage.getItem('equipment_manufacturers'));
        if (vehicles.date_fetched > (current_timestamp - (60 * 60))) {
            fetch_data = false;
            response(vehicles.data);
        }
    }
    if (fetch_data) {
        axios.defaults.withCredentials = true;
        axios.get(`${API_ROOT}/equipment/manufacturers`, {withCredentials: true})
            .then(function (ajax_response) {
                var vehicles = {};
                vehicles.data = {};
                vehicles.data = ajax_response.data;
                vehicles.date_fetched = current_timestamp;
                localStorage.setItem('equipment_manufacturers', JSON.stringify(vehicles));
                response(vehicles.data);
            })
            .catch(function (error) {
                response({});
            });
    }
}

export function getEquipmentTypesSub(response) {
    var fetch_data = true;
    var current_timestamp = Math.round((new Date()).getTime() / 1000);
    if (localStorage.getItem('equipment_types_sub') !== null) {
        var vehicles = JSON.parse(localStorage.getItem('equipment_types_sub'));
        if (vehicles.date_fetched > (current_timestamp - (60 * 60))) {
            fetch_data = false;
            response(vehicles.data);
        }
    }
    if (fetch_data) {
        axios.defaults.withCredentials = true;
        axios.get(`${API_ROOT}/equipment/types/sub`, {withCredentials: true})
            .then(function (ajax_response) {
                var vehicles = {};
                vehicles.data = {};
                vehicles.data = ajax_response.data;
                vehicles.date_fetched = current_timestamp;
                localStorage.setItem('equipment_types_sub', JSON.stringify(vehicles));
                response(vehicles.data);
            })
            .catch(function (error) {
                response({});
            });
    }
}

class EquipmentStatus extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            status_print: "",
            status_css: ""
        };
    }

    componentDidMount() {
        this.update_badge()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.status !== this.props.status) {
            this.update_badge()
        }
    }

    update_badge() {
        if (this.props.status == 1) {
            var status_print = "In Service";
            var status_css = "success";
        } else if (this.props.status == 2) {
            var status_print = "Out of Service";
            var status_css = "warning";
        } else {
            var status_print = "Retired";
            var status_css = "dark";
        }
        this.setState({
            status_print: status_print,
            status_css: "badge badge-" + status_css
        });
    }

    render() {
        return (
            <div className={this.state.status_css}>{this.state.status_print}</div>
        );
    }
}

export function addEquipmentPhoto(response) {

}

export default EquipmentStatus;
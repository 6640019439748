import React from "react";
import axios from 'axios';
import moment from 'moment';
import {API_ROOT} from '../../api-config';

export function getDrivers(response) {
    axios.defaults.withCredentials = true;
    axios.get(`${API_ROOT}/drivers/`, {withCredentials: true})
        .then(function (ajax_response) {
            var vehicles = {};
            vehicles.data = {};
            vehicles.data = ajax_response.data;
            response(vehicles.data);
        })
        .catch(function (error) {
            response({});
        });
}

export function getDriversLogged(response) {
    axios.defaults.withCredentials = true;
    axios.get(`${API_ROOT}/drivers/logged/`, {withCredentials: true})
        .then(function (ajax_response) {
            var vehicles = {};
            vehicles.data = {};
            vehicles.data = ajax_response.data;
            response(vehicles.data);
        })
        .catch(function (error) {
            response({});
        });
}

export default getDrivers;
const Menu = [
    {
        name: 'Dashboard',
        path: 'dashboard',
        icon: 'fa fa-home',
        roles: [1, 2],
    },
    {
        name: 'Fleet Map',
        path: 'map',
        icon: 'fas fa-map-marker-alt',
        roles: [1],
        code: 'FM'
    },
    {
        name: 'Loads',
        path: 'loads',
        icon: 'fas fa-truck-loading',
        roles: [1, 2],
        code: 'LO'
    },
    {
        name: 'Invoices',
        path: 'invoices',
        icon: 'fas fa-file-invoice',
        roles: [1],
        code: 'IN'
    }, {
        name: 'Vehicles',
        path: 'vehicles',
        icon: 'fas fa-truck',
        roles: [1],
        code: 'VE'
    },
    {
        name: 'Equipment',
        path: 'equipment',
        icon: 'fas fa-trailer',
        roles: [1],
        code: 'EQ'
    }, {
        name: 'Fill-Ups',
        path: 'fillups',
        icon: 'fa-solid fa-gas-pump',
        roles: [1],
        code: 'FU'
    },
    {
        name: 'Customers',
        path: 'customers',
        icon: 'fas fa-store',
        roles: [1],
        code: 'CU'
    }, {
        name: 'Drivers',
        path: 'drivers',
        icon: 'far fa-id-card',
        roles: [1],
        code: 'DR'
    }, {
        name: 'Staff',
        path: 'staff',
        icon: 'fas fa-id-card-alt',
        roles: [1],
        code: 'ST'
    }, {
        name: 'Reports',
        icon: 'fas fa-chart-line',
        minRole: 1,
        code: 'RE',
        submenu: [
            {
                name: 'Idle Time',
                path: 'reports/idle',
                icon: 'fas fa-smog',
            }
        ]
    }, {
        name: 'Trackers',
        path: 'trackers',
        icon: 'fas fa-satellite-dish',
        roles: [1],
        code: 'TR'
    }
];

export default Menu;

import React from 'react';
import {withRouter, Switch, Route, Redirect} from 'react-router-dom';
import {TransitionGroup, CSSTransition} from 'react-transition-group';

import Base from './components/Layout/Base';
import BasePage from './components/Layout/BasePage';

import Dashboard from './components/Dashboard/Dashboard';
import VehiclePage from './components/Vehicles/VehiclePage';
import VehicleList from './components/Vehicles/VehicleList';

import LoadsPage from './components/Loads/LoadsPage';
import LoadsList from './components/Loads/LoadsList';

import DriversList from './components/Drivers/DriversList';
import DriverPage from './components/Drivers/DriverPage';


import CustomerList from './components/Customers/CustomerList';
import CustomerPage from './components/Customers/CustomerPage';

import EquipmentPage from './components/Equipment/EquipmentPage';
import EquipmentList from './components/Equipment/EquipmentList';

import FillupsList from './components/Fillups/FillupsList';

import ListStaff from './components/Staff/ListStaff'

import InvoiceCreate from './components/Invoices/InvoiceCreate'
import InvoicesList from './components/Invoices/InvoicesList'
import InvoicePage from './components/Invoices/InvoicePage'

import Login from './components/Pages/Login';
import NotFound from './components/Pages/NotFound';

import AppliedRoute from "./components/Common/AppliedRoute";
import MapFleet from "./components/Map/MapFleet";

import Idle from "./components/Reports/Idle";



// List of routes that uses the page layout
// listed here to Switch between layouts
// depending on the current pathname
const listofPages = [
    '/login',
    '/notfound',
    '/error500',
    '/maintenance'
];

const Routes = ({location, childProps}) => {
    const currentKey = location.pathname.split('/')[1] || '/';
    const timeout = {enter: 500, exit: 500};

    // Animations supported
    //      'rag-fadeIn'
    //      'rag-fadeInUp'
    //      'rag-fadeInDown'
    //      'rag-fadeInRight'
    //      'rag-fadeInLeft'
    //      'rag-fadeInUpBig'
    //      'rag-fadeInDownBig'
    //      'rag-fadeInRightBig'
    //      'rag-fadeInLeftBig'
    //      'rag-zoomBackDown'
    const animationName = 'rag-fadeIn'

    if (listofPages.indexOf(location.pathname) > -1) {
        if (childProps.isAuthenticated && (location.pathname === "/login" || location.pathname === "/register")) {
            return (
                <Redirect to='/dashboard'/>
            )
        } else {
            return (
                // Page Layout component wrapper
                <BasePage>
                    <Switch location={location}>
                        <AppliedRoute path="/login" component={Login} props={childProps}/>
                        <Route path="/notfound" component={NotFound}/>
                    </Switch>
                </BasePage>
            )
        }
    } else {
        if (childProps.isAuthenticated === false) {
            return (
                <Redirect to='/login'/>
            )
        } else {
            return (
                // Layout component wrapper
                // Use <BaseHorizontal> to change layout
                <Base {...childProps} location={location}>
                    <TransitionGroup>
                        <CSSTransition key={currentKey} timeout={timeout} classNames={animationName} exit={false}>
                            <div>
                                <Switch location={location}>
                                    <AppliedRoute path="/dashboard" component={Dashboard} props={childProps}/>
                                    <AppliedRoute path="/map" component={MapFleet} props={childProps}/>
                                    <AppliedRoute path="/loads/create" component={LoadsPage} props={childProps}/>
                                    <AppliedRoute path="/loads/:load_id" component={LoadsPage} props={childProps}/>
                                    <AppliedRoute path="/loads" component={LoadsList} props={childProps}/>
                                    <AppliedRoute path="/invoices/create" component={InvoiceCreate} props={InvoiceCreate}/>
                                    <AppliedRoute path="/invoices/:invoice_id" component={InvoicePage} props={childProps}/>
                                    <AppliedRoute path="/invoices" component={InvoicesList} props={InvoicesList}/>
                                    <AppliedRoute path="/equipment/:equipment_id" component={EquipmentPage} props={childProps}/>
                                    <AppliedRoute path="/equipment" component={EquipmentList} props={childProps}/>
                                    <AppliedRoute path="/vehicles/:equipment_id" component={VehiclePage} props={childProps}/>
                                    <AppliedRoute path="/vehicles" component={VehicleList} props={childProps}/>
                                    <AppliedRoute path="/fillups" component={FillupsList} props={childProps}/>
                                    <AppliedRoute path="/drivers/:driver_id" component={DriverPage} props={childProps}/>
                                    <AppliedRoute path="/drivers" component={DriversList} props={ListStaff}/>
                                    <AppliedRoute path="/customer/:customer_id" component={CustomerPage} props={childProps}/>
                                    <AppliedRoute path="/customers" component={CustomerList} props={childProps}/>
                                    <AppliedRoute path="/staff" component={ListStaff} props={ListStaff}/>
                                    <AppliedRoute path="/reports/idle" component={Idle} props={Idle}/>
                                    <Redirect to="/login"/>
                                </Switch>
                            </div>
                        </CSSTransition>
                    </TransitionGroup>
                </Base>
            )
        }
    }
}

export default withRouter(Routes);

import React, {Component} from 'react';
import ContentWrapper from '../Layout/ContentWrapper';
import {Link} from 'react-router-dom';
import {
    Card,
    CardBody,
    Row,
    Col,
    InputGroup,
    Button,
    Input,
    InputGroupAddon,
    UncontrolledButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Modal,
    ModalHeader,
    ModalBody
} from 'reactstrap';
import axios from 'axios';
import {API_ROOT} from '../../api-config';
import Swal from 'sweetalert2'
import 'lity/dist/lity.min.js';
import 'lity/dist/lity.min.css';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import 'moment-timezone';
import AddPhoto from '../Equipment/AddPhoto';
import AddFillup from '../Fillups/AddFillup';


import {getEquipmentTypes, getEquipmentTypesSub, getEquipmentManufacturers} from "../Equipment/EquipmentFunctions";
import money from "money-math";

moment.tz.setDefault("America/Halifax");

class EquipmentPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            equipment_id: null,
            equipment: {
                loading: true,
                name: "",
                equipment_type_id: null,
                equipment_type_sub_id: null,
                province: '',
                plate: "",
                vin: "",
                acquired_odo: 0,
                status: 1,
                equipment_manufacturer_id: null,
                model: "",
                year: null,
                colour: "",
                height_inches: null,
                width_inches: null,
                axles: null,
                weight_empty_kg: null,
                weight_gawr_kgs: null,
                weight_gvwr_kgs: null,
                length_inches: null,
                acquired_cost: null,
                acquired_from: "",
                date_acquired: null,
                photos: []
            },
            province_list: ['NB', 'NS', 'PE', 'NL', 'ON', 'QC', 'SK', 'MB', 'AB', 'BC'],

            fillups: {
                list: [],
                loading: true,
            },
            fillup: {
                fillup_id: null,
                driver: "(select driver)",
                equipment_id: null,
                fill_date: null,
                odometer: "",
                litres: "",
                price_per_litre: "",
                pst_fuel: "",
                gst_fuel: "",
                total_tax_fuel: money.floatToAmount(0),
                total_fuel: money.floatToAmount(0),
                other: "",
                tax_other: "",
                total_other: money.floatToAmount(0),
                total: money.floatToAmount(0),
                location: "",
                chain: "(select chain)",
                driver_id: null,
            },

            status_name: "(select)",
            equipment_statuses: {1: "In Service", 2: "Out of Service", 0: "Retired"},

            equipment_type_name: "(select)",
            equipment_types: {},

            equipment_type_sub_name: "(select)",
            equipment_types_sub: {},

            equipment_manufacturer_name: "(select)",
            equipment_manufacturers: {},


            safety_inspections: [],

            trips: {
                list: [],
                loading: true,
                subtotal: 0,
                tax: 0,
                deposit: 0,
                total: 0,
                kegs: 0,
                cases: 0
            },
            maint_records: {
                list: [],
                loading: true,
            },
            safety_inspections_modal: false
        };
        this.equipmentChange = this.equipmentChange.bind(this);
        this.addPhotos = this.addPhotos.bind(this);
        this.refreshFillup = this.refreshFillup.bind(this);
    }

    componentDidMount() {
        document.title = "Equipment # " + this.props.match.params.equipment_id + " | Greenhaven Transportation";
        this.setState({equipment_id: this.props.match.params.equipment_id})
        var self = this;
        axios.defaults.withCredentials = true;
        getEquipmentTypes(function (equipment_types) {
            getEquipmentTypesSub(function (equipment_types_sub) {
                getEquipmentManufacturers(function (equipment_manufacturers) {
                    axios.get(API_ROOT + '/equipment/' + self.props.match.params.equipment_id)
                        .then(function (response) {
                            response.data.loading = false;
                            self.setState({
                                equipment_types: equipment_types,
                                equipment_types_sub: equipment_types_sub,
                                equipment_manufacturers: equipment_manufacturers,
                                equipment: response.data,
                                equipment_id: self.props.match.params.equipment_id,
                                status_name: self.state.equipment_statuses[response.data.status],
                                equipment_type_name: (response.data.equipment_type_id > 0 ? equipment_types[response.data.equipment_type_id].name : "(select)"),
                                equipment_type_sub_name: (response.data.equipment_type_sub_id > 0 ? equipment_types_sub[response.data.equipment_type_sub_id].name : "(select)"),
                                equipment_manufacturer_name: (response.data.equipment_manufacturer_id > 0 ? equipment_manufacturers[response.data.equipment_manufacturer_id].name : "(select)"),
                            });
                            self.refreshFillup();
                        })
                        .catch(function (error) {
                            /*if (error.response.status===401){
                                self.props.userSignOut()
                            } else {
                                Swal("Error", error.response.data.Message, "error");
                            }*/
                        });
                });
            });
        });
    }

    refreshFillup = (fillup_id) => {
        var self = this;
        axios.get(API_ROOT + '/fillups/?equipment_id=' + this.props.match.params.equipment_id)
            .then(function (response) {
                self.setState(
                    {
                        fillups: {
                            list: response.data,
                            loading: false,
                        }
                    })
                ;
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    self.props.userSignOut()
                } else {
                    Swal("Error", error.response.data.Message, "error");
                }
            });
    }

    loadCard() {
        var self = this;
        axios.get(API_ROOT + '/photos/?equipment_id=' + this.props.match.params.equipment_id)
            .then(function (response) {
                self.setState(
                    {
                        photos: {
                            list: response.data,
                            loading: false,
                        }
                    })
                ;
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    self.props.userSignOut()
                } else {
                    Swal("Error", error.response.data.Message, "error");
                }
            });
    }

    equipmentChange(event) {
        var state = this.state.equipment;
        state[event.target.name] = event.target.value;
        this.setState({
            equipment: state
        });
    }

    changeProvince = (province) => {
        var equipment = this.state.equipment;
        equipment.province = province;
        this.setState({
            equipment: equipment,
        });
    }

    changeEquipmentType = (equipment_type_id) => {
        var equipment_type_name = this.state.equipment_types[equipment_type_id].name;
        var equipment = this.state.equipment;
        equipment.equipment_type_id = equipment_type_id;
        this.setState({
            equipment: equipment,
            equipment_type_name: equipment_type_name,
            equipment_type_sub_name: "(select)"
        });
    }

    changeEquipmentTypeSub = (equipment_type_sub_id) => {
        var equipment_type_sub_name = this.state.equipment_types_sub[equipment_type_sub_id].name
        var equipment = this.state.equipment;
        equipment.equipment_type_sub_id = equipment_type_sub_id;
        this.setState({
            equipment: equipment,
            equipment_type_sub_name: equipment_type_sub_name
        });
    }

    changeEquipmentManufacturer = (equipment_manufacturer_id) => {
        var equipment_manufacturer_name = this.state.equipment_manufacturers[equipment_manufacturer_id].name
        var equipment = this.state.equipment;
        equipment.equipment_manufacturer_id = equipment_manufacturer_id;
        this.setState({
            equipment: equipment,
            equipment_manufacturer_name: equipment_manufacturer_name
        });
    }

    saveEquipmentDetails = async event => {
        if (event) {
            event.preventDefault();
        }
        var self = this;
        axios.defaults.withCredentials = true;
        axios.put(API_ROOT + '/equipment/' + this.props.match.params.equipment_id, {data: this.state.equipment})
            .then(function (response) {
                toast.success("Changes Saved", {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    self.props.userSignOut()
                } else {
                    Swal("Error", error.response.data.Message, "error");
                }
            });
    };

    goToOrder = (order_id) => {
        this.props.history.push("/order/" + order_id);
    };

    createBOL = async event => {
        this.props.history.push("/bol/create/" + this.state.equipment_id);
    };

    goToBOL = (bol_id) => {
        this.props.history.push("/bol/" + bol_id);
    };

    safety_inspections_toggle = () => {
        this.setState({
            safety_inspections_modal: !this.state.safety_inspections_modal
        });
    };

    addPhotos = (new_photo) => {
        var equipment = this.state.equipment;
        equipment['photos'].push({hash: new_photo, description: ''});
        this.setState({
            equipment: equipment
        })
    };

    viewFillup = (fillup) => {
        this.setState({
            fillup: fillup
        })
    }


    render() {
        return (
            <ContentWrapper>
                <div className="content-heading">
                    <div>
                        <Link to="../vehicles">Vehicles</Link>&nbsp;/&nbsp; {this.state.equipment.name}
                    </div>
                </div>
                <Row>
                    <Col lg={6}>
                        <Card className={(this.state.equipment.loading ? "card card-default whirl traditional" : "card card-default")}>
                            <CardBody>
                                <p className="lead bb">Details</p>
                                <form className="form-horizontal" onSubmit={this.saveEquipmentDetails}>
                                    <Row className="mb-1">
                                        <label className="col-xl-3 col-form-label pt-0">Number</label>
                                        <div className="col-xl-9">
                                            <Input size="sm" type="text" name="name" value={this.state.equipment.name} required onChange={this.equipmentChange}/>
                                        </div>
                                    </Row>
                                    <Row className="mb-1">
                                        <label className="col-xl-3 col-form-label pt-0">Type</label>
                                        <div className="col-xl-9">
                                            <UncontrolledButtonDropdown>
                                                <DropdownToggle size="sm" color="secondary" caret style={{fontSize: '13px', width: 'auto'}}>
                                                    {this.state.equipment_type_name}
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    {Object.keys(this.state.equipment_types).map((key, i) => {
                                                            if (typeof this.state.equipment_types != "undefined" && typeof this.state.equipment_types[key] != "undefined") {
                                                                return (<DropdownItem key={key}
                                                                                      onClick={() => this.changeEquipmentType(key)}>{this.state.equipment_types[key].name}</DropdownItem>)
                                                            }
                                                        }
                                                    )}
                                                </DropdownMenu>
                                            </UncontrolledButtonDropdown>
                                            <i className="fas fa-angle-right ml-2 mr-2"></i>
                                            <UncontrolledButtonDropdown>
                                                <DropdownToggle size="sm" color="secondary" caret style={{fontSize: '13px', width: 'auto'}}>
                                                    {this.state.equipment_type_sub_name}
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    {Object.keys(this.state.equipment_types_sub).map((key, i) => {
                                                            if (typeof this.state.equipment_types_sub != "undefined" && typeof this.state.equipment_types_sub[key] != "undefined" && this.state.equipment.equipment_type_id == this.state.equipment_types_sub[key].equipment_type_id) {
                                                                return (
                                                                    <DropdownItem key={key}
                                                                                  onClick={() => this.changeEquipmentTypeSub(key)}>{this.state.equipment_types_sub[key].name}</DropdownItem>
                                                                )
                                                            }
                                                        }
                                                    )}
                                                </DropdownMenu>
                                            </UncontrolledButtonDropdown>
                                        </div>
                                    </Row>
                                    <Row className={this.state.equipment.equipment_type_id == 2 ? "mb-1" : "d-none"}>
                                        <label className="col-xl-3 col-form-label pt-0">Is a Reefer?</label>
                                        <div className="col-xl-9">

                                        </div>
                                    </Row>
                                    <Row className="mb-1">
                                        <label className="col-xl-3 col-form-label pt-0">Make & Model</label>
                                        <div className="col-xl-9">
                                            <InputGroup size="sm">
                                                <UncontrolledButtonDropdown>
                                                    <DropdownToggle size="sm" color="secondary" caret style={{fontSize: '13px', width: 'auto'}}>
                                                        {this.state.equipment_manufacturer_name}
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        {Object.keys(this.state.equipment_manufacturers).map((key, i) => {
                                                                if (typeof this.state.equipment_manufacturers != "undefined" && typeof this.state.equipment_manufacturers[key] != "undefined") {
                                                                    return (<DropdownItem key={key}
                                                                                          onClick={() => this.changeEquipmentManufacturer(key)}>{this.state.equipment_manufacturers[key].name}</DropdownItem>)
                                                                }
                                                            }
                                                        )}
                                                    </DropdownMenu>
                                                </UncontrolledButtonDropdown>
                                                <i className="fas fa-angle-right mt-2 ml-2 mr-2"></i>
                                                <Input size="sm" type="text" name="model" value={this.state.equipment.model} onChange={this.equipmentChange}/>
                                            </InputGroup>
                                        </div>
                                    </Row>
                                    <Row className="mb-1">
                                        <label className="col-xl-3 col-form-label pt-0">Year Made</label>
                                        <div className="col-xl-9">
                                            <Input size="sm" type="number" name="year" value={this.state.equipment.year} onChange={this.equipmentChange}/>
                                        </div>
                                    </Row>
                                    <Row className="mb-1">
                                        <label className="col-xl-3 col-form-label pt-0">VIN</label>
                                        <div className="col-xl-9">
                                            <Input size="sm" type="text" name="vin" value={this.state.equipment.vin} onChange={this.equipmentChange}/>
                                        </div>
                                    </Row>
                                    <Row className="mb-1">
                                        <label className="col-xl-3 col-form-label pt-0">Colour</label>
                                        <div className="col-xl-9">
                                            <Input size="sm" type="text" name="colour" value={this.state.equipment.colour} onChange={this.equipmentChange}/>
                                        </div>
                                    </Row>
                                    <Row className="mb-1">
                                        <label className="col-xl-3 col-form-label pt-0">Province of Reg.</label>
                                        <div className="col-xl-9">
                                            <UncontrolledButtonDropdown>
                                                <DropdownToggle color="secondary" size="sm" caret style={{fontSize: '13px', width: 'auto'}}>
                                                    {this.state.equipment.province}
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    {this.state.province_list.map((province, i) =>
                                                        <DropdownItem key={province} onClick={() => this.changeProvince(province)}>{province}</DropdownItem>
                                                    )}
                                                </DropdownMenu>
                                            </UncontrolledButtonDropdown>
                                        </div>
                                    </Row>
                                    <Row className="mb-1">
                                        <label className="col-xl-3 col-form-label pt-0">Plate #</label>
                                        <div className="col-xl-9">
                                            <Input size="sm" type="text" name="plate" value={this.state.equipment.plate} onChange={this.equipmentChange}/>
                                        </div>
                                    </Row>
                                    <Row className="mb-1">
                                        <label className="col-xl-3 col-form-label pt-0">Status</label>
                                        <div className="col-xl-9">
                                            <UncontrolledButtonDropdown>
                                                <DropdownToggle color="secondary" size="sm" caret style={{fontSize: '13px', width: 'auto'}}>
                                                    {this.state.status_name}
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    {Object.keys(this.state.equipment_statuses).map((key, i) =>
                                                        <DropdownItem key={key} onClick={() => this.changeEquipmentType(key)}>{this.state.equipment_statuses[key]}</DropdownItem>
                                                    )}
                                                </DropdownMenu>
                                            </UncontrolledButtonDropdown>
                                        </div>
                                    </Row>

                                    <div className="border m-0 p-1 mb-1 bg-light rounded">
                                        <div className="row pb-1">
                                            <label className="col-xl-3 col-form-label pt-0"></label>
                                            <div className="col-xl-9 small text-bold">SPECIFICATIONS:</div>
                                        </div>
                                        <Row className="mb-1">
                                            <label className="col-xl-4 col-form-label pt-0"># of Axles</label>
                                            <div className="col-xl-8">
                                                <Input size="sm" type="text" name="axles" className="text-right" value={this.state.equipment.axles}
                                                       onChange={this.equipmentChange}/>
                                            </div>
                                        </Row>
                                        <Row className="mb-1">
                                            <label className="col-xl-4 col-form-label pt-0">Height <small>(exterior)</small></label>
                                            <div className="col-xl-8">
                                                <InputGroup size="sm">
                                                    <Input type="text" name="height_inches" className="text-right" value={this.state.equipment.height_inches}
                                                           onChange={this.equipmentChange}/>
                                                    <InputGroupAddon addonType="append">inches</InputGroupAddon>
                                                </InputGroup>
                                            </div>
                                        </Row>
                                        <Row className="mb-1">
                                            <label className="col-xl-4 col-form-label pt-0">Width <small>(exterior)</small></label>
                                            <div className="col-xl-8">
                                                <InputGroup size="sm">
                                                    <Input size="sm" type="text" name="width_inches" className="text-right" value={this.state.equipment.width_inches}
                                                           onChange={this.equipmentChange}/>
                                                    <InputGroupAddon addonType="append">inches</InputGroupAddon>
                                                </InputGroup>
                                            </div>
                                        </Row>
                                        <Row className="mb-1">
                                            <label className="col-xl-4 col-form-label pt-0">Length <small>(exterior)</small></label>
                                            <div className="col-xl-8">
                                                <InputGroup size="sm">
                                                    <Input size="sm" type="text" name="length_inches" className="text-right" value={this.state.equipment.length_inches}
                                                           onChange={this.equipmentChange}/>
                                                    <InputGroupAddon addonType="append">inches</InputGroupAddon>
                                                </InputGroup>
                                            </div>
                                        </Row>
                                        <Row className="mb-1">
                                            <label className="col-xl-4 col-form-label pt-0">Tare Weight <small>(excl. cargo)</small></label>
                                            <div className="col-xl-8">
                                                <InputGroup size="sm">
                                                    <Input size="sm" type="text" name="weight_empty_kg" className="text-right" value={this.state.equipment.weight_empty_kg}
                                                           onChange={this.equipmentChange}/>
                                                    <InputGroupAddon size="sm" addonType="append">kg</InputGroupAddon>
                                                </InputGroup>
                                            </div>
                                        </Row>
                                        <Row className="mb-1">
                                            <label className="col-xl-4 col-form-label pt-0">GAWR</label>
                                            <div className="col-xl-8">
                                                <InputGroup size="sm">
                                                    <Input size="sm" type="text" name="weight_gawr_kg" className="text-right" value={this.state.equipment.weight_gawr_kg}
                                                           onChange={this.equipmentChange}/>
                                                    <InputGroupAddon size="sm" addonType="append">kg</InputGroupAddon>
                                                </InputGroup>
                                            </div>
                                        </Row>
                                        <Row className="mb-0">
                                            <label className="col-xl-4 col-form-label pt-0">GVWR</label>
                                            <div className="col-xl-8">
                                                <InputGroup size="sm">
                                                    <Input size="sm" type="text" name="weight_gvwr_kg" className="text-right" value={this.state.equipment.weight_gvwr_kg}
                                                           onChange={this.equipmentChange}/>
                                                    <InputGroupAddon size="sm" addonType="append">kg</InputGroupAddon>
                                                </InputGroup>
                                            </div>
                                        </Row>
                                    </div>

                                    <div className="border m-0 p-1 mb-1 bg-light rounded">
                                        <div className="row pb-1">
                                            <label className="col-xl-3 col-form-label pt-0"></label>
                                            <div className="col-xl-9 small text-bold">ACQUIRED:</div>
                                        </div>
                                        <Row className="mb-1">
                                            <label className="col-xl-3 col-form-label pt-0">Purchase Date</label>
                                            <div className="col-xl-9">
                                                <Input size="sm" type="date" name="date_acquired" value={this.state.equipment.date_acquired} onChange={this.equipmentChange}/>
                                            </div>
                                        </Row>
                                        <Row className="mb-1">
                                            <label className="col-xl-3 col-form-label pt-0">Acquired From</label>
                                            <div className="col-xl-9">
                                                <Input size="sm" type="text" name="acquired_from" value={this.state.equipment.acquired_from} onChange={this.equipmentChange}/>
                                            </div>
                                        </Row>
                                        <Row className="mb-0">
                                            <label className="col-xl-3 col-form-label pt-0">Purchase Price</label>
                                            <div className="col-xl-9">
                                                <InputGroup size="sm">
                                                    <InputGroupAddon size="sm" addonType="append">$</InputGroupAddon>
                                                    <Input size="sm" type="text" name="acquired_odo" className="text-right" value={this.state.equipment.acquired_odo}
                                                           onChange={this.equipmentChange}/>
                                                </InputGroup>
                                            </div>
                                        </Row>
                                    </div>

                                    <Row className="mb-1">
                                        <label className="col-xl-3 col-form-label pt-0">Notes</label>
                                        <div className="col-xl-9">
                                            <Input type="textarea" style={{minHeight: "50px"}} name="notes" value={this.state.equipment.notes} onChange={this.equipmentChange}/>
                                        </div>
                                    </Row>
                                    <Row className="">
                                        <div className="offset-xl-3 col-xl-9">
                                            <button className="btn btn-primary" type="submit">Save Changes</button>
                                        </div>
                                    </Row>
                                </form>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg={6}>
                        <Card className="card card-default d-none">
                            <CardBody>
                                <p className="lead bb">Current Status</p>
                                <Row>
                                    <label className="pt-0 col-xl-4 col-form-label">Speed</label>
                                    <div className="col-xl-8">
                                        <div className="text-bold">14km/hr <small>17 minutes ago</small></div>
                                    </div>
                                </Row>
                                <Row>
                                    <label className="pt-0 col-xl-4 col-form-label">Location</label>
                                    <div className="col-xl-8">

                                    </div>
                                </Row>
                            </CardBody>
                        </Card>
                        <Card className="card card-default d-none">
                            <CardBody>
                                <p className="lead bb">Maintenance</p>
                                <Row>
                                    <label className="pt-0 col-xl-4 col-form-label">Current Odometers</label>
                                    <div className="col-xl-8">
                                        230,000 km | 112,283 miles
                                    </div>
                                </Row>
                                <Row>
                                    <label className="pt-0 col-xl-4 col-form-label">Next Service Due</label>
                                    <div className="col-xl-8">
                                        240,000km (approximately 17 days, 14 hours to go)
                                    </div>
                                </Row>
                                <Row>
                                    <label className="pt-0 col-xl-4 col-form-label">Safety Inspection Expiry</label>
                                    <div className="col-xl-8">
                                        June 1, 2024 <small>in 330 days</small>
                                    </div>
                                </Row>
                            </CardBody>
                        </Card>
                        <Card className="card card-default d-none">
                            <CardBody>
                                <p className="lead bb">Statistics <div className="float-right small">period: <a href="#">30 days</a> | <a href="#">365 days</a> | lifetime</div></p>
                                <Row>
                                    <label className="pt-0 col-xl-4 col-form-label">Miles Driven</label>
                                    <div className="col-xl-8">
                                        <div className="text-bold">23,213 miles <small>(916 per day)</small></div>
                                    </div>
                                </Row>
                                <Row>
                                    <label className="pt-0 col-xl-4 col-form-label">Avg MPG</label>
                                    <div className="col-xl-8">
                                        <div className="text-bold">11.2 mpg</div>
                                    </div>
                                </Row>
                                <Row>
                                    <label className="pt-0 col-xl-4 col-form-label">Avg MPG</label>
                                    <div className="col-xl-8">
                                        <div className="text-bold">11.2 mpg</div>
                                    </div>
                                </Row>
                                <Row>
                                    <label className="pt-0 col-xl-4 col-form-label">Maintenance $/Mile</label>
                                    <div className="col-xl-8">
                                        <div className="text-bold">$10.2/mile / $8.1/km</div>
                                    </div>
                                </Row>
                                <Row>
                                    <label className="pt-0 col-xl-4 col-form-label">Cost $/Mile <small>(incl. fuel)</small></label>
                                    <div className="col-xl-8">
                                        <div className="text-bold">$19.2/mile / $14.1/km</div>
                                    </div>
                                </Row>
                            </CardBody>
                        </Card>
                        <Card className={(this.state.loading ? "card card-default whirl traditional" : "card card-default")}>
                            <CardBody>
                                <div className="lead bb">
                                    {this.state.equipment.photos.length} Photo{(this.state.equipment.photos.length > 1 ? 's' : '')}
                                    <div className="float-right">
                                        <AddPhoto equipment_id={this.props.match.params.equipment_id} addPhotos={this.addPhotos}/>
                                    </div>
                                </div>
                                <div>
                                    {this.state.equipment.photos.map(function (id, key) {
                                        return (
                                            <img src={"../img/photos/" + id['hash'] + ".jpg"} title={id['description']} class="img-fluid"/>
                                        )
                                    }, this)}
                                </div>
                            </CardBody>
                        </Card>
                        <Card className="card card-default">
                            <CardBody>
                                <p className="lead bb">Fill-Ups
                                    <div className="float-right">
                                        <AddFillup equipment_id={this.props.match.params.equipment_id} refreshFillup={this.refreshFillup} viewFillup={this.state.fillup}/>
                                    </div></p>
                                <table
                                    className={(this.state.fillups.list.length > 0 && !this.state.fillups.loading ? "table table-sm table-hover table-pointer text-right" : "d-none")}>
                                    <thead>
                                    <tr>
                                        <th className="text-left">Date</th>
                                        <th>ODO</th>
                                        <th>Litres</th>
                                        <th>Per Litre</th>
                                        <th>Total</th>
                                        <th>MPG</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.fillups.list.map(function (item, key) {
                                        return (
                                            <tr key={key} onClick={this.viewFillup.bind(this, item)}>
                                                <td className="text-left">{moment(item.fill_date).format("MMM. D/YY HH:mm")}</td>
                                                <td>{item.odometer}</td>
                                                <td>{item.litres}</td>
                                                <td>${item.price_per_litre}</td>
                                                <td>${item.total}</td>
                                                <td>{(item.mpg == null ? "NA" : item.mpg.toFixed(1))}</td>
                                            </tr>
                                        )
                                    }, this)}
                                    </tbody>
                                </table>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Card className={(this.state.trips.loading ? "card card-default whirl traditional d-none" : "card card-default")}>
                    <CardBody>
                        <p className="lead bb">{this.state.trips.list.length} Trips</p>
                        <div className="table-responsive">
                            <table className={(this.state.trips.list.length > 0 && !this.state.trips.loading ? "table table-hover table-pointer text-right" : "d-none")}>
                                <thead>
                                <tr>
                                    <th className="d-none d-lg-table-cell">#</th>
                                    <th className="d-none d-md-table-cell">Kegs</th>
                                    <th className="d-none d-md-table-cell">Cases</th>
                                    <th className="d-none d-lg-table-cell">Subtotal</th>
                                    <th className="d-none d-lg-table-cell">Tax</th>
                                    <th className="d-none d-lg-table-cell">Deposit</th>
                                    <th>Charge</th>
                                    <th className="d-none d-sm-table-cell text-left">Del. Method</th>
                                    <th className="d-none d-sm-table-cell text-left">Pay. Method</th>
                                    <th className="text-left">Date/Time</th>
                                    <th className="d-none d-sm-table-cell">Pay. Status</th>
                                    <th className="d-none d-sm-table-cell">Del. Status</th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.trips.list.map(function (item, key) {
                                    if (item.order_id > 0) {
                                        return (
                                            <tr key={key} onClick={this.goToOrder.bind(this, item.order_id)}
                                                style={{"text-decoration": (item.is_paid === null || item.void_date != null ? "line-through" : "none")}}>
                                                <td className="d-none d-lg-table-cell">{item.order_id}</td>
                                                <td className="d-none d-md-table-cell">{(item.num_30l + item.num_50l)}</td>
                                                <td className="d-none d-md-table-cell">{(item.num_355ml + item.num_473ml)}</td>
                                                <td className="d-none d-lg-table-cell">${item.subtotal.toFixed(2)}</td>
                                                <td className="d-none d-lg-table-cell">${item.tax.toFixed(2)}</td>
                                                <td className="d-none d-lg-table-cell">${item.deposit.toFixed(2)}</td>
                                                <td>${item.total.toFixed(2)}</td>
                                                <td className="d-none d-sm-table-cell text-left">{(item.inventory_source_id == 0 || typeof this.state.inventory_sources[item.inventory_source_id] == "undefined" ? "--" : this.state.inventory_sources[item.inventory_source_id].name)}</td>
                                                <td className="d-none d-sm-table-cell text-left">{(item.payment_method == 1 ? "Credit Card" : "Cheque")}</td>
                                                <td className="text-left">{item.date_short}</td>
                                                <td className="d-none d-sm-table-cell">
                                                </td>
                                                <td className="d-none d-sm-table-cell">
                                                </td>
                                            </tr>
                                        )
                                    }
                                }, this)}
                                </tbody>
                                <tfoot>
                                <tr className="font-weight-bold bg-gray-lighter">
                                    <td className="d-none d-lg-table-cell">Total:</td>
                                    <td className="d-none d-sm-table-cell text-left"></td>
                                    <td className="d-none d-sm-table-cell text-left"></td>
                                    <td></td>
                                    <td className="d-none d-sm-table-cell"></td>
                                    <td className="d-none d-sm-table-cell"></td>
                                </tr>
                                </tfoot>
                            </table>
                        </div>
                    </CardBody>
                </Card>
                <Modal isOpen={this.state.safety_inspections_modal} toggle={this.safety_inspections_toggle} size="md">
                    <ModalHeader>
                        <div className="h3 mb-0">Safety Inspections</div>
                    </ModalHeader>
                    <ModalBody>
                        <table className="table table-sm table-hover table-pointer">
                            <thead>
                            <tr>
                                <th className="d-none d-lg-table-cell">Date</th>
                                <th className="d-none d-md-table-cell">Amount</th>
                                <th className="d-none d-md-table-cell">Category</th>
                                <th className="d-none d-lg-table-cell">Details</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.safety_inspections.map(function (item, key) {
                                return (
                                    <tr key={key}>
                                        <td>{moment(item.date).format('YYYY-MM-DD')}</td>
                                        <td className="text-right">${item.amount.toFixed(2)}</td>
                                        <td>{item.type}</td>
                                        <td>{item.details}</td>
                                    </tr>
                                )
                            }, this)}
                            </tbody>
                        </table>
                    </ModalBody>
                </Modal>
                <ToastContainer/>
            </ContentWrapper>
        );
    }

}

export default EquipmentPage;

import React from 'react';
import {Link} from 'react-router-dom';
import ContentWrapper from '../Layout/ContentWrapper';
import {Row, Col, Button} from 'reactstrap';
import Swal from 'sweetalert2'
import axios from 'axios';
import {API_ROOT} from '../../api-config';
import moment from 'moment';
import 'moment-timezone';

moment.tz.setDefault("America/Halifax");

class Dashboard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            events: [],
            todos: [],
            loading_events: true,
            loading_todo: true
        };
        this.goToCall = this.goToCall.bind(this);
    }

    componentDidMount() {
        if (localStorage.getItem('role_id') < 9) {
            console.log(localStorage.getItem('role_id'))
            var self = this;
        }
    }

    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        console.log('triggered')
    }

    goToCall = (vehicle_id, call_id) => {
        this.props.history.push("/equipment/" + vehicle_id + "/call/" + call_id);
    };

    render() {
        return (
            <ContentWrapper>
                <div className="content-heading">
                    <div>
                        Dashboard
                    </div>
                </div>
                <Row>
                    <div className="col-lg-6">

                    </div>
                    <div className="col-lg-6">

                    </div>
                </Row>
            </ContentWrapper>
        );
    }
}

export default (Dashboard);
import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
import {API_ROOT} from '../../api-config';
import Swal from 'sweetalert2'

class Login extends Component {

    constructor(props) {
        super(props);

        this.state = {
            email: "",
            password: ""
        };
    }

    validateForm() {
        return this.state.email.length > 0 && this.state.password.length > 0;
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    handleSubmit = async event => {
        event.preventDefault();

        var self = this;
        axios.defaults.withCredentials = true;
        axios.post(`${API_ROOT}/login`, {withCredentials: true, email: this.state.email, password: this.state.password})
            .then(function (response) {
                localStorage.setItem('staff_id', response.data.staff_id);
                localStorage.setItem('first_name', response.data.first_name);
                localStorage.setItem('last_name', response.data.last_name);
                localStorage.setItem('permissions', response.data.permissions);
                localStorage.setItem('role_id', JSON.stringify(response.data.role_id));
                self.props.refresh_from_local();
                self.props.userHasAuthenticated(true);
                self.props.history.push("/dashboard");
            })
            .catch(function (error) {
                Swal("Error", error.response.data.Message, "error");
            });
    }

    render() {
        return (
            <div className="block-center mt-4 wd-xl">
                <div className="card card-flat">
                    <div className="card-header text-center bg-dark">
                        <a href="">
                            <img className="block-center rounded" src="img/logo.svg" alt="Logo"/>
                        </a>
                    </div>
                    <div className="card-body">
                        <p className="text-center py-2">STAFF PORTAL</p>
                        <form className="mb-3" id="loginForm" onSubmit={this.handleSubmit}>
                            <div className="form-group">
                                <div className="input-group with-focus">
                                    <input className="form-control border-right-0" id="email" type="email" placeholder="Enter email" autoComplete="off" onChange={this.handleChange}
                                           required/>
                                    <div className="input-group-append">
                                        <span className="input-group-text fa fa-envelope text-muted bg-transparent border-left-0"></span>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="input-group with-focus">
                                    <input className="form-control border-right-0" id="password" type="password" placeholder="Password" onChange={this.handleChange} required/>
                                    <div className="input-group-append">
                                        <span className="input-group-text text-muted bg-transparent border-left-0"><i className="fas fa-lock"></i></span>
                                    </div>
                                </div>
                            </div>
                            <button className="btn btn-block btn-primary mt-3" type="submit" disabled={!this.validateForm()}>Sign In</button>
                        </form>
                    </div>
                </div>
                <div className="p-3 text-center">
                    <span className="mr-2">&copy;</span>
                    <span>2023</span>
                    <span className="mx-2">-</span>
                    <span>Greenhaven Transportation</span>

                </div>
            </div>
        );
    }
}

export default Login;

import React from 'react';
import {Link} from 'react-router-dom';
import ContentWrapper from '../Layout/ContentWrapper';
import {Button, DropdownItem, DropdownToggle, DropdownMenu, UncontrolledButtonDropdown} from 'reactstrap';
import axios from 'axios';
import {API_ROOT} from '../../api-config';
import Swal from 'sweetalert2'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import 'moment-timezone';
import getCustomers from "../Customers/CustomerFunctions";

moment.tz.setDefault("America/Halifax");

var money = require("money-math");

class InvoicesList extends React.Component {
    constructor(props) {
        super(props);

        this.updateDates = this.updateDates.bind(this);
        this.goToInvoice = this.goToInvoice.bind(this);

        this.state = {
            customers: {},
            customers_sort: [],
            customer_id: null,
            loading: true,
            invoices: {},
            invoices_sort: [],
            startDate: (localStorage.getItem('inv_startDate') != null ? moment(localStorage.getItem('inv_startDate')) : moment().endOf('day')),
            endDate: (localStorage.getItem('inv_endDate') != null ? moment(localStorage.getItem('inv_endDate')) : moment().add(6, 'days')),
            ranges: {
                'Today': [moment(), moment()],
                'Next 7 Days': [moment().add(6, 'days'), moment().endOf('day')],
                'Next 30 Days': [moment().add(30, 'days'), moment().endOf('day')],
                'Last 7 Days': [moment().subtract(6, 'days'), moment().endOf('day')],
                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                'All Time': [moment("01 Jan 2023"), moment("31 Dec 2024")]
            },
            sort: (localStorage.getItem('sort3') != null ? localStorage.getItem('sort3') : "invoice_id"),
            sort_order: (localStorage.getItem('sort_order3') != null ? localStorage.getItem('sort_order3') * 1 : -1),
        };
    }

    componentDidMount() {
        document.title = "Invoices | Greenhaven Transportation";
        var self = this;
        getCustomers(function (customers) {
            var customers_sort = [];
            for (const customer_id of Object.keys(customers)) {
                customers_sort.push(customers[customer_id]);
            }
            var customers_sort = customers_sort.sort(function (a, b) {
                if (a.name < b.name) {
                    return -1
                } else {
                    return 1
                }
            });
            self.setState({
                customers: customers,
                customers_sort: customers_sort,
            }, function () {
                this.update_invoices()
            });
        });
    }

    update_invoices() {
        var self = this;
        axios.defaults.withCredentials = true;
        axios.get(API_ROOT + '/invoices/?customer_id=' + self.state.customer_id + "&startDate=" + self.state.startDate + "&endDate=" + self.state.endDate)
            .then(function (response) {
                self.setState({
                        loads: response.data,
                        loading: false,
                    }
                );
                self.onSort(null, self.state.sort)
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    self.props.userSignOut()
                } else {
                    Swal("Error", error.response.data.Message, "error");
                }
            });
    }

    // on date range change
    updateDates(event, picker) {
        if (event.type == "apply") {
            localStorage.setItem('inv_startDate', picker.startDate);
            localStorage.setItem('inv_endDate', picker.endDate);
            this.setState({
                startDate: picker.startDate,
                endDate: picker.endDate
            }, function () {
                this.update_invoices()
            });
        }
    }

    changeCustomer = (customer_id) => {
        this.setState({
            customer_id: customer_id,
        }, function () {
            this.update_invoices()
        });
    };

    updateValue(event, name, value) {
        if (event.target) {
            event.preventDefault();
            event.stopPropagation();
        }
        if (typeof name == "undefined") {
            name = event.target.name
        }
        if (typeof value == "undefined") {
            value = event.target.value
        }
        this.setState({
            [name]: value
        }, function () {
            this.update_invoices()
        });
    }

    goToInvoice = (load_id) => {
        this.props.history.push("/invoices/" + load_id);
    };

    onSort(event, sortKey) {
        localStorage.setItem('sort3', sortKey);
        localStorage.setItem('sort_order3', this.state.sort_order);
        var sort_order = this.state.sort_order * -1;
        if (sortKey != this.state.sort) {
            sort_order = 1;
        }

        function format_search_val(val) {
            if (typeof val == "undefined") {
                return 1;
            } else if (isNaN(val)) {
                return val.toLowerCase();
            } else {
                return parseFloat(val);
            }
        }

        var invoices_sort = [];
        for (const load_id of Object.keys(this.state.loads)) {
            invoices_sort.push(this.state.loads[load_id])
        }
        var invoices = invoices_sort.sort((a, b) => {
            if (typeof format_search_val(a[sortKey]) == "string" && typeof format_search_val(b[sortKey]) != "string") {
                return (sort_order == -1 ? -1 : 1);
            } else {
                if (format_search_val(a[sortKey]) > format_search_val(b[sortKey])) {
                    return (sort_order == -1 ? -1 : 1);
                }
                if (format_search_val(a[sortKey]) < format_search_val(b[sortKey])) {
                    return (sort_order == -1 ? 1 : -1);
                }
            }
            return 0;
        });
        this.setState({
            sort: sortKey,
            sort_equipment: sort_order,
            invoices_sort: invoices
        });
    }

    render() {
        // date range selector variables
        let start = this.state.startDate.format('MMM. D/YY');
        let end = this.state.endDate.format('MMM. D/YY');
        let label = start + ' - ' + end;
        if (start === end) {
            label = start;
        }
        var loads = 0;
        var total = money.floatToAmount(0);
        var total_ar = money.floatToAmount(0);

        return (
            <ContentWrapper>
                <div className="content-heading">
                    <div>Invoices</div>
                    <div className="ml-auto">
                        <Link to="invoices/create" className="btn btn-primary"><i className="fas fa-plus"></i> Create Invoice</Link>
                    </div>
                </div>
                <div className="mb-3 form-inline" style={{zIndex: 1000}}>
                    <DateRangePicker
                        onEvent={this.updateDates}
                        autoUpdateInput={true}
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        ranges={this.state.ranges}
                    >
                        <button type="button" className="btn btn-primary selected-date-range-btn mr-1">
                            Summary Period: <b>{label}</b> <span className="fas fa-caret-down fa-sm"></span>
                        </button>
                    </DateRangePicker>
                    <UncontrolledButtonDropdown className="mr-1">
                        <DropdownToggle color="primary" caret style={{fontSize: '13px', width: 'auto'}}>
                            {(this.state.customer_id == null ? "All Customers" : this.state.customers[this.state.customer_id].name)}
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem key="null" onClick={() => this.updateValue(false, 'customer_id', null)}>All Customers</DropdownItem>
                            <DropdownItem divider/>
                            {Object.keys(this.state.customers_sort).map((key, i) => {
                                return (<DropdownItem key={key}
                                                      onClick={() => this.updateValue(false, 'customer_id', this.state.customers_sort[key].customer_id)}>{this.state.customers_sort[key].name}</DropdownItem>)
                            })}
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                </div>
                <div className={(this.state.loading ? "card card-default whirl traditional" : "card card-default")}>
                    <div>
                        <div className="card-header">
                            <div className="card-title">{this.state.invoices_sort.length} Invoices</div>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className={(this.state.invoices_sort.length > 0 ? "table table-hover table-pointer text-right" : "d-none")}>
                                    <thead>
                                    <tr>
                                        <th className="text-left" onClick={e => this.onSort(e, 'invoice_id')}><i className="fas fa-sort"></i> Invoice #</th>
                                        <th className="text-left" onClick={e => this.onSort(e, 'date')}><i className="fas fa-sort"></i> Date</th>
                                        <th className="text-left" onClick={e => this.onSort(e, 'customer_id')}><i className="fas fa-sort"></i> Customer</th>
                                        <th className="text-right" onClick={e => this.onSort(e, 'loads')}><i className="fas fa-sort"></i> # of Loads</th>
                                        <th className="text-right" onClick={e => this.onSort(e, 'total')}><i className="fas fa-sort"></i> Amount</th>
                                        <th className="text-right" onClick={e => this.onSort(e, 'total_ar')}><i className="fas fa-sort"></i> AR Balance</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {Object.entries(this.state.invoices_sort).map(([key, invoice]) => {
                                        loads = (loads + invoice.loads);
                                        total = money.add(total, money.floatToAmount(invoice.total));
                                        //total_ar = money.add(total_ar, money.floatToAmount(invoice.total_ar));
                                        return (
                                            <tr key={key} onClick={this.goToInvoice.bind(this, invoice.invoice_id)}>
                                                <td className="text-left">{invoice.invoice_id}</td>
                                                <td className="text-left">{(invoice.invoice_date == null ? "--" : moment(invoice.invoice_date).format("MMM. D/YY"))}</td>
                                                <td className="text-left">{(typeof this.state.customers[invoice.customer_id] == "undefined" ? "" : this.state.customers[invoice.customer_id].name)}</td>
                                                <td className="text-right">{invoice.loads}</td>
                                                <td className="text-right">${invoice.total.toFixed(2).toLocaleString()}</td>
                                                <td className="text-right">${0}</td>
                                            </tr>
                                        )
                                    })}
                                    </tbody>
                                    <tfoot>
                                    <tr>
                                        <th className="text-right" colSpan="3">Total:</th>
                                        <th className="text-right">{loads}</th>
                                        <th className="text-right">${total}</th>
                                        <th className="text-right">${0}</th>
                                    </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        );
    }
}

export default (InvoicesList);